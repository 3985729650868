import React, { useState, useEffect } from "react";
import {
  CurrencyDollarIcon,
  UserPlusIcon,
  XMarkIcon,
  ArrowPathIcon,
  ClockIcon,
  ArrowDownIcon,
  TrashIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

const InputField = ({ value, onChange, placeholder, type = "text" }) => (
  <input
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    type={type}
    placeholder={placeholder}
  />
);

const GroupSpending = () => {
  const [people, setPeople] = useState([{ name: "", amount: "" }]);
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/group-spending/history`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const historyData = await response.json();
        setHistory(historyData);
      }
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const handleAddPerson = () => {
    setPeople([...people, { name: "", amount: "" }]);
  };

  const handleRemovePerson = (index) => {
    const newPeople = people.filter((_, i) => i !== index);
    setPeople(newPeople);
  };

  const handleChange = (index, field, value) => {
    const newPeople = [...people];
    newPeople[index][field] = value;
    setPeople(newPeople);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setResult(null);
    setIsLoading(true);

    const data = people.reduce((acc, person) => {
      if (person.name && person.amount) {
        acc[person.name] = parseFloat(person.amount);
      }
      return acc;
    }, {});

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/group-spending/calculate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to calculate");
      }
      const result = await response.json();
      setResult(result);
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!result) return;

    setIsSaving(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/group-spending/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            people: people.reduce((acc, person) => {
              if (person.name && person.amount) {
                acc[person.name] = parseFloat(person.amount);
              }
              return acc;
            }, {}),
            result: result,
          }),
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to save calculation");
      }
      await response.json();
      fetchHistory(); // Refresh history after saving
    } catch (error) {
      setError("An error occurred while saving. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this calculation?")) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/group-spending/delete/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete calculation");
      }
      await response.json();
      fetchHistory(); // Refresh history after deleting
    } catch (error) {
      setError("An error occurred while deleting. Please try again.");
    }
  };

  const handleImport = async (id) => {
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/group-spending/fetch/${id}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch calculation");
      }
      const calculation = await response.json();
      setPeople(
        Object.entries(calculation.people).map(([name, amount]) => ({
          name,
          amount: amount.toString(),
        }))
      );
      setResult(calculation.result);
    } catch (error) {
      setError(
        "An error occurred while importing the calculation. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto my-8 px-4 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6 text-indigo-700">
        Group Trip Expense Calculator
      </h1>
      <form
        onSubmit={handleSubmit}
        className="mb-6 bg-white shadow-md rounded-lg p-6"
      >
        {people.map((person, index) => (
          <div key={index} className="mb-4">
            <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
              <div className="flex-1">
                <InputField
                  value={person.name}
                  onChange={(value) => handleChange(index, "name", value)}
                  placeholder="Name"
                />
              </div>
              <div className="flex-1">
                <InputField
                  type="number"
                  value={person.amount}
                  onChange={(value) => handleChange(index, "amount", value)}
                  placeholder="Amount"
                />
              </div>
              <button
                type="button"
                onClick={() => handleRemovePerson(index)}
                className="w-full sm:w-auto px-2 py-1.5 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center justify-center"
              >
                <XMarkIcon className="h-5 w-5 mr-1" /> Remove
              </button>
            </div>
          </div>
        ))}
        <div className="mt-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <button
            type="button"
            onClick={handleAddPerson}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center"
          >
            <UserPlusIcon className="h-5 w-5 mr-2" /> Add Person
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 disabled:opacity-50 flex items-center justify-center"
          >
            {isLoading ? (
              <>
                <ArrowPathIcon className="h-5 w-5 mr-2 animate-spin" />{" "}
                Calculating...
              </>
            ) : (
              <>
                <CurrencyDollarIcon className="h-5 w-5 mr-2" /> Calculate
              </>
            )}
          </button>
        </div>
      </form>
      {error && (
        <div className="p-4 text-red-700 bg-red-100 border border-red-400 rounded-lg mt-6">
          {error}
        </div>
      )}
      {result && (
        <div className="bg-white shadow-lg rounded-lg mt-6 overflow-hidden">
          <div className="bg-indigo-500 text-white p-4 flex justify-between items-center">
            <h2 className="text-2xl font-bold">Results</h2>
            <button
              onClick={handleSave}
              disabled={isSaving}
              className="px-6 py-3 bg-white text-indigo-500 rounded-lg hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 disabled:opacity-50 flex items-center text-lg font-medium transition-colors duration-200"
            >
              {isSaving ? (
                <>
                  <ArrowPathIcon className="h-6 w-6 mr-2 animate-spin" />{" "}
                  Saving...
                </>
              ) : (
                <>
                  <ArrowDownIcon className="h-6 w-6 mr-2" /> Save Calculation
                </>
              )}
            </button>
          </div>
          <div className="p-6">
            <div className="mb-6 bg-indigo-50 p-4 rounded-lg">
              <p className="text-lg font-semibold text-indigo-700">
                Average per person: ${result.average.toFixed(2)}
              </p>
            </div>
            <h3 className="font-bold text-xl mb-4 text-gray-700">
              Transactions:
            </h3>
            <ul className="space-y-2">
              {result.transactions.map((transaction, index) => (
                <li
                  key={index}
                  className="bg-gray-50 p-3 rounded-lg shadow flex items-center justify-between"
                >
                  <span className="font-medium text-gray-700">
                    {transaction.from}
                  </span>
                  <span className="text-green-600 font-bold">
                    pays ${transaction.amount.toFixed(2)}
                  </span>
                  <span className="font-medium text-gray-700">
                    {transaction.to}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {history.length > 0 && (
        <div className="bg-white shadow-lg rounded-lg mt-6 overflow-hidden">
          <div className="bg-indigo-500 text-white p-4">
            <h2 className="text-2xl font-bold flex items-center">
              <ClockIcon className="h-6 w-6 mr-2" /> Calculation History
            </h2>
          </div>
          <div className="p-6">
            <ul className="space-y-4">
              {history.map((calc) => (
                <li
                  key={calc.id}
                  className="border-b pb-4 flex justify-between items-center"
                >
                  <div>
                    <p className="font-semibold">
                      Date: {new Date(calc.createdAt).toLocaleString()}
                    </p>
                    <p>Average: ${calc.result.average.toFixed(2)}</p>
                    <p>Participants: {Object.keys(calc.people).join(", ")}</p>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleImport(calc.id)}
                      className="text-blue-500 hover:text-blue-700 focus:outline-none bg-blue-100 hover:bg-blue-200 rounded-full p-3 transition-colors duration-200"
                      title="Import calculation"
                    >
                      <ArrowUturnLeftIcon className="h-6 w-6" />
                    </button>
                    <button
                      onClick={() => handleDelete(calc.id)}
                      className="text-red-500 hover:text-red-700 focus:outline-none bg-red-100 hover:bg-red-200 rounded-full p-3 transition-colors duration-200"
                      title="Delete calculation"
                    >
                      <TrashIcon className="h-6 w-6" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupSpending;
