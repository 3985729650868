import React, { useEffect, useRef, useState } from 'react';

const LazyImage = ({ src, alt, className, ...props }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const currentImgRef = imgRef.current; // Capture the current value

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsLoaded(true);
                    observer.unobserve(currentImgRef); // Use captured value
                }
            },
            {
                rootMargin: '100px',
                threshold: 0.1
            }
        );

        if (currentImgRef) { // Use captured value
            observer.observe(currentImgRef);
        }

        return () => {
            if (currentImgRef) { // Use captured value
                observer.unobserve(currentImgRef);
            }
        };
    }, []); // Empty dependency array is fine here

    return (
        <div ref={imgRef} className={`relative ${className}`}>
            {!isLoaded && (
                <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
            )}
            {isLoaded && (
                <img
                    src={src}
                    alt={alt}
                    className={`transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'
                        }`}
                    {...props}
                />
            )}
        </div>
    );
};

export default LazyImage;