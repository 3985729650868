import {
  CloudIcon,
  CodeBracketSquareIcon,
  ServerStackIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import LazyImage from './LazyImage';
const About = () => {
  return (
    <div className="bg-gray-100 sm:py-32 lg:overflow-visible">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Nodejs+ React?
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                About
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Welcome to my digital realm. This website is the culmination of
                several weeks of dedicated effort, marking my personal foray
                into full-stack development.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <LazyImage
            alt="Code example"
            src="/about page code.png"
            className="w-full max-w-screen-sm rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] lg:w-[48rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                This project was particularly exciting as it was my first
                opportunity to apply my newly acquired skills in Node.js, a
                language I had recently mastered.
              </p>
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Infrastructure
                    </strong>{" "}
                    The project infrastructure was established on a Virtual
                    Private Server (VPS), which I rented as part of an
                    Infrastructure as a Service (IaaS) model. I also registered
                    a unique domain name to give my project a distinct identity.
                    The configuration process was intricate and time-consuming,
                    involving the use of SSH for access and the installation and
                    setup of Nginx, with a particular focus on resolving
                    deployment issues.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CodeBracketSquareIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Frontend
                    </strong>{" "}
                    The website is powered by ReactJS, complemented by Tailwind,
                    a modern CSS framework.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerStackIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Backend
                    </strong>{" "}
                    The backend is built on Node.js, with PassportJS handling
                    authentication and using MySQL to handle data.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ArrowPathIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      CI/CD
                    </strong>{" "}
                    Incorporating CI/CD practices, I have established a workflow
                    with GitHub Actions. This ensures that every code push to
                    GitHub triggers an automatic build and deployment on my VPS.
                  </span>
                </li>
              </ul>

              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Future
              </h2>
              <p className="mt-6">
                To further enhance the project, I have integrated generative AI,
                showcasing its potential to assist in complex development tasks.
                This website is not merely a personal project, but a
                demonstration of the seamless integration of diverse
                technologies and methodologies in full-stack development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
