import React, { useState, useEffect } from "react";

const MEASUREMENTS = {
  fuelConsumption: [
    { value: "L/100km", label: "L/100km" },
    { value: "km/L", label: "km/L" },
    { value: "MPG", label: "MPG" },
  ],
  fuelPrice: [
    { value: "$/L", label: "$/L" },
    { value: "$/US Gallon", label: "$/US Gallon" },
  ],
  mileage: [
    { value: "km", label: "km" },
    { value: "miles", label: "miles" },
  ],
};

const InputField = ({ label, value, onChange, placeholder, type = "text", helper }) => (
  <div className="mb-4">
    <label className="block text-gray-800">{label}</label>
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-1"
      type={type}
      placeholder={placeholder}
    />
    {helper && <p className="mt-2 text-sm text-gray-400">{helper}</p>}
  </div>
);

const SelectField = ({ label, value, onChange, options }) => (
  <div className="mb-4">
    <label className="block text-gray-800">{label}</label>
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-1"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

const CarInputs = ({ car, onChange }) => (
  <div className="p-6 rounded-lg bg-white shadow-md">
    <h5 className="text-lg font-bold text-center mb-4">{car.type}</h5>
    <InputField
      label="Price"
      value={car.price}
      onChange={(value) => onChange({ ...car, price: value })}
      placeholder="Price"
      type="number"
    />
    <InputField
      label="Fuel Consumption"
      value={car.fuelConsumption}
      onChange={(value) => onChange({ ...car, fuelConsumption: value })}
      placeholder="Fuel consumption"
      type="number"
    />
    <SelectField
      label="Fuel Consumption Measurement"
      value={car.fuelConsumptionMeasurement}
      onChange={(value) =>
        onChange({ ...car, fuelConsumptionMeasurement: value })
      }
      options={MEASUREMENTS.fuelConsumption}
    />
  </div>
);

export default function PetrolHybridCalculator() {
  const [inputs, setInputs] = useState({
    petrol: {
      type: "Petrol",
      price: "",
      fuelConsumption: "",
      fuelConsumptionMeasurement: "L/100km",
    },
    hybrid: {
      type: "Hybrid",
      price: "",
      fuelConsumption: "",
      fuelConsumptionMeasurement: "L/100km",
    },
    fuelPrice: "",
    fuelPriceMeasurement: "$/L",
    mileage: "",
    mileageMeasurement: "km",
  });
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calculations, setCalculations] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    fetchCalculations();
  }, []);

  const fetchCalculations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/petrolhybrid/history`, {
        credentials: 'include'
      });
      if (!response.ok) {
        if (response.status === 401) {
          setIsAuthenticated(false);
          setCalculations([]);
          return;
        }
        throw new Error("Failed to fetch calculations");
      }
      const data = await response.json();
      setCalculations(data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error fetching calculations:", error);
      setIsAuthenticated(false);
      setCalculations([]);
    }
  };

  const handleChange = (field, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [field]: value }));
  };

  const handleCalculate = async () => {
    const {
      petrol,
      hybrid,
      fuelPrice,
      fuelPriceMeasurement,
      mileage,
      mileageMeasurement,
    } = inputs;

    if (
      ![
        petrol.price,
        petrol.fuelConsumption,
        hybrid.price,
        hybrid.fuelConsumption,
        fuelPrice,
        fuelPriceMeasurement,
        mileage,
        mileageMeasurement,
      ].every(Boolean)
    ) {
      setError("Please fill in all fields before calculating.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/petrolhybrid/calculate`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: 'include',
          body: JSON.stringify({
            petrolCarPrice: petrol.price,
            petrolFuelConsumption: petrol.fuelConsumption,
            hybridCarPrice: hybrid.price,
            hybridFuelConsumption: hybrid.fuelConsumption,
            fuelConsumptionMeasurement: petrol.fuelConsumptionMeasurement,
            fuelPrice,
            fuelPriceMeasurement,
            mileage,
            mileageMeasurement,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      setResults(data);
      await saveCalculation(data);
    } catch (error) {
      console.error("Error calculating results:", error);
      setError(
        error.message || "Failed to calculate results. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const saveCalculation = async (calculationResult) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/petrolhybrid/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({
          petrolCarDetails: inputs.petrol,
          hybridCarDetails: inputs.hybrid,
          calculationParams: {
            fuelPrice: inputs.fuelPrice,
            fuelPriceMeasurement: inputs.fuelPriceMeasurement,
            mileage: inputs.mileage,
            mileageMeasurement: inputs.mileageMeasurement
          },
          result: calculationResult
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save calculation");
      }

      await fetchCalculations(); // Refresh the list of calculations
    } catch (error) {
      console.error("Error saving calculation:", error);
    }
  };

  const loadCalculation = (calculation) => {
    setInputs({
      petrol: calculation.petrolCarDetails,
      hybrid: calculation.hybridCarDetails,
      ...calculation.calculationParams
    });
    setResults(calculation.result);
  };

  return (
    <div className="container mx-auto my-8 max-w-4xl">
      <h1 className="text-4xl font-bold text-center mb-8">Petrol vs Hybrid Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <CarInputs
          car={inputs.petrol}
          onChange={(updatedCar) =>
            handleChange("petrol", updatedCar)
          }
        />
        <CarInputs
          car={inputs.hybrid}
          onChange={(updatedCar) =>
            handleChange("hybrid", updatedCar)
          }
        />
      </div>

      <div className="p-6 rounded-lg mt-6 bg-white shadow-md">
        <InputField
          label="Fuel Price"
          value={inputs.fuelPrice}
          onChange={(value) => handleChange("fuelPrice", value)}
          placeholder="Fuel price"
          type="number"
        />
        <SelectField
          label="Fuel Price Measurement"
          value={inputs.fuelPriceMeasurement}
          onChange={(value) => handleChange("fuelPriceMeasurement", value)}
          options={MEASUREMENTS.fuelPrice}
        />
        <InputField
          label="Mileage per Year"
          value={inputs.mileage}
          onChange={(value) => handleChange("mileage", value)}
          placeholder="Mileage/year"
          type="number"
        />
        <SelectField
          label="Mileage Measurement"
          value={inputs.mileageMeasurement}
          onChange={(value) => handleChange("mileageMeasurement", value)}
          options={MEASUREMENTS.mileage}
        />
      </div>

      <div className="mt-6 text-center">
        <button
          onClick={handleCalculate}
          disabled={isLoading}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          {isLoading ? "Calculating..." : "Calculate"}
        </button>
      </div>

      {error && (
        <div className="p-4 text-red-700 bg-red-100 border border-red-400 rounded-lg mt-6">
          {error}
        </div>
      )}

      {results && (
        <div className="p-6 rounded-lg mt-6 text-center bg-white shadow-md">
          <h5 className="text-lg font-bold mb-4">Result</h5>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-left font-medium">Price difference:</div>
            <div className="text-right text-orange-500">${results.priceGap.toFixed(2)}</div>
            <div className="text-left font-medium">Fuel consumption difference:</div>
            <div className="text-right text-orange-500">
              {results.fuelGap.toFixed(2)} {inputs.petrol.fuelConsumptionMeasurement}
            </div>
            <div className="text-left font-medium">Mileage to payback:</div>
            <div className="text-right text-orange-500">
              {results.mileageToPayback.toLocaleString(undefined, { maximumFractionDigits: 1 })} km
            </div>
            <div className="text-left font-medium">Year to payback:</div>
            <div className="text-right text-orange-500">
              {results.yearToPayback.toLocaleString(undefined, { maximumFractionDigits: 1 })} years
            </div>
          </div>
        </div>
      )}

      {isAuthenticated && (
        <div className="mt-10 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Calculation History</h2>
          {calculations.length > 0 ? (
            <ul className="space-y-3">
              {calculations.map((calc) => (
                <li key={calc.id} className="border-b border-gray-200 pb-2">
                  <button
                    onClick={() => loadCalculation(calc)}
                    className="w-full text-left hover:bg-gray-50 transition duration-150 ease-in-out p-2 rounded"
                  >
                    <span className="text-sm text-gray-500">
                      {new Date(calc.createdAt).toLocaleString()}
                    </span>
                    <br />
                    <span className="font-medium text-blue-600">
                      Year to payback: {calc.result.yearToPayback.toFixed(1)} years
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 italic">No calculations saved yet.</p>
          )}
        </div>
      )}
    </div>
  );
}