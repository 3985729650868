import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";

const Header = ({ user, onLogout }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await onLogout();
    navigate("/auth");
  };

  const AuthButton = () => {
    if (user) {
      return (
        <button
          onClick={handleLogout}
          className="text-sm font-semibold leading-6 hover:text-gray-300"
        >
          Log out
        </button>
      );
    } else {
      return (
        <Link
          to="/auth"
          className="text-sm font-semibold leading-6 hover:text-gray-300"
        >
          Log in <span aria-hidden="true">&rarr;</span>
        </Link>
      );
    }
  };

  return (
    <header>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">CMCC</span>
            <img
              src="/mstile-144x144.png"
              alt="CMCC Logo"
              className="h-8 w-auto"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <Link
            to="/about"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            About
          </Link>
          <Link
            to="/dashboard"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            Dashboard
          </Link>
          <Link
            to="/petrolHybridCal"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            Petrol vs Hybrid
          </Link>
          <Link
            to="/novatedLeaseCal"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            Novated Lease Calculator
          </Link>
          <Link
            to="/powerballGenerator"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            Powerball Generator
          </Link>
          <Link
            to="/groupSpending"
            className="text-sm font-semibold leading-6 hover:text-gray-300"
          >
            Group Spending Calculator
          </Link>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <AuthButton />
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">CMCC</span>
              <img
                src="/mstile-144x144.png"
                alt="CMCC Logo"
                className="h-8 w-auto"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About
                </Link>
                <Link
                  to="/dashboard"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Dashboard
                </Link>
                <Link
                  to="/petrolHybridCal"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Petrol vs Hybrid
                </Link>
                <Link
                  to="/novatedLeaseCal"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Novated Lease Calculator
                </Link>
                <Link
                  to="/powerballGenerator"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Powerball Generator
                </Link>
                <Link
                  to="/groupSpending"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Group Spending Calculator
                </Link>
              </div>
              <div className="py-6">
                <AuthButton />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;