import React, { useState, useEffect, useCallback } from "react";
import GlobalLoading from "./GlobalLoading";

function Dashboard({ user }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchDashboardData = useCallback(async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/dashboard`,
        {
          method: "GET",
          credentials: 'include',
        }
      );
      if (!res.ok) {
        throw new Error("Failed to fetch dashboard data");
      }
      // Process dashboard data if needed
    } catch (err) {
      setError("You are not authenticated. Please log in.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (isLoading) {
    return <GlobalLoading />;
  }

  return (
    <div className="relative isolate bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <h1 className="text-3xl font-bold text-gray-900">
            Welcome to your Dashboard
          </h1>
          <UserInfo user={user} />
        </div>
      </div>
    </div>
  );
}

function ErrorMessage({ message }) {
  return (
    <div className="relative isolate min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            {message}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Redirecting to login page...
          </p>
          <button
            onClick={() => (window.location.href = "/auth")}
            className="mt-4 text-indigo-600 hover:text-indigo-500"
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
}

function UserInfo({ user }) {
  if (!user) {
    return null;
  }

  return (
    <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          User Information
        </h2>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Username</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.username}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Access Level</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.accessId}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Dashboard;