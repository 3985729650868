import React, { useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const PowerballGenerator = () => {
    const [games, setGames] = useState([]);
    const [numOfGames, setNumOfGames] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const generateGames = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/powerball/generate-games`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ numOfGames }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGames(data.games);
        } catch (error) {
            console.error('Error generating games:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-6">
            <h2 className="text-3xl font-bold text-center text-indigo-600">Powerball Generator</h2>
            <div className="space-y-2">
                <label htmlFor="numOfGames" className="block text-sm font-medium text-gray-700">Number of Games</label>
                <input
                    id="numOfGames"
                    type="number"
                    min="1"
                    value={numOfGames}
                    onChange={(e) => setNumOfGames(e.target.value)}
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                onClick={generateGames}
                disabled={isLoading}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition duration-150 ease-in-out flex items-center justify-center"
            >
                {isLoading ? (
                    <>
                        <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                        Generating...
                    </>
                ) : (
                    'Generate'
                )}
            </button>
            {games.length > 0 && (
                <div className="bg-gray-50 p-4 rounded-md">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Generated Games:</h3>
                    <ul className="space-y-3">
                        {games.map((game, index) => (
                            <li key={index} className="bg-white p-3 rounded-md shadow-sm">
                                <span className="font-medium text-indigo-600">Game {index + 1}:</span>
                                <div className="mt-1 flex items-center space-x-2">
                                    {game.numbers.map((number, idx) => (
                                        <span key={idx} className="inline-flex items-center justify-center w-8 h-8 bg-[#3653a4] rounded-full text-sm font-bold text-white">
                                            {number}
                                        </span>
                                    ))}
                                    <span className="inline-flex items-center justify-center w-8 h-8 bg-gray-500 rounded-full text-sm font-bold text-white">
                                        {game.powerball}
                                    </span>
                                </div>

                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default PowerballGenerator;