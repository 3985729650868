import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Auth from "./components/Auth";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import NotFoundPage from "./components/NotFoundPage";
import PetrolHybridCalculator from "./components/PetrolHybridCalculator";
import NovatedLeaseCal from "./components/NovatedLeaseCal";
import About from "./components/About";
import GlobalLoading from "./components/GlobalLoading";
import ParkingSignScanner from "./components/ParkingSignScanner";
import PowerballGenerator from "./components/PowerballGenerator";
import GroupSpending from "./components/GroupSpending";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUserAuth();
  }, []);

  const checkUserAuth = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/check`,
        {
          method: "GET",
          credentials: 'include',
        }
      );
      if (res.ok) {
        const data = await res.json();
        if (data.isAuthenticated) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } else {
        console.error("Failed to check authentication status");
        setUser(null);
      }
    } catch (error) {
      console.error("Error checking user auth:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/auth/logout`,
        {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        setUser(null);
      } else {
        throw new Error("Failed to logout");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) {
    return <GlobalLoading />;
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Header user={user} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Navigate to="/auth" />} />
          <Route path="/petrolHybridCal" element={<PetrolHybridCalculator />} />
          <Route path="/novatedLeaseCal" element={<NovatedLeaseCal />} />
          <Route path="/powerballGenerator" element={<PowerballGenerator />} />
          <Route path="/auth" element={<Auth setUser={setUser} />} />
          <Route
            path="/dashboard"
            element={user ? <Dashboard user={user} /> : <Navigate to="/auth" />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/parkingSignScanner" element={<ParkingSignScanner />} />
          <Route path="/groupSpending" element={<GroupSpending />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
