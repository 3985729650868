import React, { useState, useEffect } from "react";

// Reusable InputField component
const InputField = ({ label, name, value, onChange, step, helper }) => (
  <div className="mb-4">
    <label className="block text-gray-800">{label}</label>
    <input
      type="number"
      step={step || "1"}
      name={name}
      value={value}
      onChange={onChange}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-1"
    />
    {helper && <p className="mt-2 text-sm text-gray-400">{helper}</p>}
  </div>
);

const NovatedLeaseCal = () => {
  const [carDetails, setCarDetails] = useState({
    newCarPrice: 0,
    leaseTerm: 0,
    remainingValue: 0,
    distancePerYear: 0,
    fuelPrice: 0,
    fuelConsumption: 0,
    servicing: 0,
    insurance: 0,
    roadsideAssistance: 55,
    rego: 906,
    tyres: 300,
  });

  const [novatedLease, setNovatedLease] = useState({
    amount: 0,
    frequency: "yearly",
  });

  const [result, setResult] = useState({
    yearlyLeaseCost: 0,
    totalLeaseCost: 0,
    runningCost: 0,
    totalRunningCost: 0,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [calculations, setCalculations] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    fetchCalculations();
  }, []);

  const fetchCalculations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/novatedlease/history`, {
        credentials: 'include'
      });
      if (!response.ok) {
        if (response.status === 401) {
          setIsAuthenticated(false);
          setCalculations([]);
          return;
        }
        throw new Error("Failed to fetch calculations");
      }
      const data = await response.json();
      setCalculations(data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error fetching calculations:", error);
      setIsAuthenticated(false);
      setCalculations([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarDetails((prevDetails) => ({
      ...prevDetails,
      [name]: Number(value),
    }));
  };

  const handleNovatedLeaseChange = (e) => {
    const { name, value } = e.target;
    setNovatedLease((prevLease) => ({
      ...prevLease,
      [name]: value,
    }));
  };

  const handleCalculate = async () => {
    if (carDetails.newCarPrice <= 0 || carDetails.leaseTerm <= 0) {
      alert("Please enter valid values for car price and lease term.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/novatedlease/calculate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: 'include',
          body: JSON.stringify({ carDetails, novatedLease }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setResult(data);
      await saveCalculation(data);
    } catch (error) {
      setError("Error calculating the lease cost");
    } finally {
      setLoading(false);
    }
  };

  const saveCalculation = async (calculationResult) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/novatedlease/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({
          carDetails,
          novatedLease,
          result: calculationResult
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save calculation");
      }

      await fetchCalculations(); // Refresh the list of calculations
    } catch (error) {
      console.error("Error saving calculation:", error);
    }
  };

  const loadCalculation = (calculation) => {
    setCarDetails(calculation.carDetails);
    setNovatedLease(calculation.novatedLease);
    setResult(calculation.result);
  };

  const inputs = [
    {
      label: "Lease Term",
      name: "leaseTerm",
      helper: "How many years will the lease be?",
    },
    { label: "New Car Price", name: "newCarPrice" },
    {
      label: "Remaining Value",
      name: "remainingValue",
      helper: `What is the used car price after ${carDetails.leaseTerm} years?`,
    },
    {
      label: "Fuel Consumption (L/100km)",
      name: "fuelConsumption",
      helper: "What is the fuel consumption of the car?",
    },
    { label: "Servicing", name: "servicing" },
    {
      label: "Distance per Year (km)",
      name: "distancePerYear",
      helper: "How much do you drive a year?",
    },
    {
      label: "Fuel Price per Liter ($)",
      name: "fuelPrice",
      helper: "Cost of fuel per liter (allowing decimals)",
      step: "0.01",
    },
    { label: "Insurance ($)", name: "insurance" },
    {
      label: "Roadside Assistance ($)",
      name: "roadsideAssistance",
      helper: "Cost of roadside assistance (default is $55)",
    },
    {
      label: "Registration (Rego) ($)",
      name: "rego",
      helper: "Cost of registration (default is $906)",
    },
    {
      label: "Tyres ($)",
      name: "tyres",
      helper: "Average yearly tyres cost (default is $300, tyre last 40000km)",
    },
  ];

  return (
    <div className="mx-auto p-6 bg-gray-100 max-w-4xl">
      <h1 className="text-4xl font-bold text-center mb-4">
        Novated Lease vs Buy
      </h1>

      {inputs.map((input, index) => (
        <InputField
          key={index}
          label={input.label}
          name={input.name}
          value={carDetails[input.name]}
          onChange={handleInputChange}
          step={input.step}
          helper={input.helper}
        />
      ))}

      <div className="mb-4 flex flex-wrap md:flex-nowrap md:space-x-4">
        <div className="mb-4 flex-1">
          <InputField
            label="Novated Lease Amount ($)"
            name="amount"
            value={novatedLease.amount}
            onChange={handleNovatedLeaseChange}
          />
        </div>

        <div className="flex-none md:w-48">
          <label className="block text-gray-800">Frequency</label>
          <select
            name="frequency"
            value={novatedLease.frequency}
            onChange={handleNovatedLeaseChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-1"
          >
            <option value="weekly">Weekly</option>
            <option value="fortnightly">Fortnightly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
      </div>

      <button
        onClick={handleCalculate}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
        disabled={loading}
      >
        {loading ? "Calculating..." : "Calculate"}
      </button>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      <div className="mt-6">
        <div className="grid grid-cols-3 gap-4 bg-white p-4 rounded-lg shadow">
          <div></div>
          <div className="text-center font-semibold text-indigo-600">Novated Lease</div>
          <div className="text-center font-semibold text-green-600">Buy</div>

          <div className="font-medium text-gray-800">Yearly Cost</div>
          <div className="text-center text-gray-800">${Math.round(result.yearlyLeaseCost).toLocaleString()}</div>
          <div className="text-center text-gray-800">${Math.round(result.runningCost).toLocaleString()}</div>

          <div className="font-medium text-gray-800">Total Cost</div>
          <div className="text-center text-gray-800">${Math.round(result.totalLeaseCost).toLocaleString()}</div>
          <div className="text-center text-gray-800">${Math.round(result.totalRunningCost).toLocaleString()}</div>
        </div>
      </div>

      {isAuthenticated && (
        <div className="mt-10 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Calculation History</h2>
          {calculations.length > 0 ? (
            <ul className="space-y-3">
              {calculations.map((calc) => (
                <li key={calc.id} className="border-b border-gray-200 pb-2">
                  <button
                    onClick={() => loadCalculation(calc)}
                    className="w-full text-left hover:bg-gray-50 transition duration-150 ease-in-out p-2 rounded"
                  >
                    <span className="text-sm text-gray-500">
                      {new Date(calc.createdAt).toLocaleString()}
                    </span>
                    <br />
                    <span className="font-medium text-blue-600">
                      Total Cost: ${calc.result.totalRunningCost.toFixed(2)}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 italic">No calculations saved yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NovatedLeaseCal;