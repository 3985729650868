import React from 'react';

const GlobalLoading = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-50">
            <div className="flex flex-col items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-600 mb-4"></div>
                <p className="text-xl font-bold text-indigo-600">Loading...</p>
            </div>
        </div>
    );
};

export default GlobalLoading;