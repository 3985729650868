import React, { useState, useRef, useCallback } from 'react';
import { CameraIcon, GlobeAltIcon } from '@heroicons/react/24/solid';

const ParkingSignScanner = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [extractedText, setExtractedText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const fileInputRef = useRef(null);

    const handleImageFile = useCallback(async (file) => {
        if (!file.type.startsWith('image/')) {
            setError('Please select an image file.');
            return;
        }

        setError('');
        setIsLoading(true);
        setImageSrc(URL.createObjectURL(file));

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/parking-sign-scanner/scan`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error scanning image');
            }

            const data = await response.json();
            setExtractedText(data.text);
        } catch (error) {
            console.error('Error:', error);
            setError('Error scanning image. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleCapture = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            handleImageFile(file);
        }
    }, [handleImageFile]);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            handleImageFile(file);
        }
    }, [handleImageFile]);

    const handlePaste = useCallback((e) => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                handleImageFile(blob);
                break;
            }
        }
    }, [handleImageFile]);

    const handleUrlSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!imageUrl) {
            setError('Please enter a valid image URL.');
            return;
        }

        setError('');
        setIsLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/parking-sign-scanner/scan-url`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imageUrl }),
            });

            if (!response.ok) {
                throw new Error('Error scanning image from URL');
            }

            const data = await response.json();
            setImageSrc(imageUrl);
            setExtractedText(data.text);
        } catch (error) {
            console.error('Error:', error);
            setError('Error scanning image from URL. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [imageUrl]);

    return (
        <div className="flex flex-col items-center justify-center p-4 space-y-4">
            <h2 className="text-xl font-bold mb-4">Parking Sign Scanner</h2>
            <div
                className="p-8 border-2 border-dashed rounded-lg border-gray-300 transition-colors duration-300 ease-in-out hover:border-blue-500 hover:bg-blue-50"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onPaste={handlePaste}
            >
                <div className="flex flex-col items-center space-y-4">
                    <button
                        onClick={() => fileInputRef.current.click()}
                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                        <CameraIcon className="w-5 h-5 mr-2" />
                        Scan Sign
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={handleCapture}
                        className="hidden"
                        ref={fileInputRef}
                    />
                    <p className="text-sm text-gray-600 text-center">
                        or drag and drop, or Ctrl+V to paste
                    </p>
                </div>
            </div>
            <form onSubmit={handleUrlSubmit} className="w-full max-w-md">
                <div className="flex items-center border-b border-blue-500 py-2">
                    <input
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="url"
                        placeholder="Enter image URL"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                    <button
                        className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                    >
                        <GlobeAltIcon className="w-5 h-5" />
                    </button>
                </div>
            </form>
            {isLoading && <p className="text-gray-600">Processing image...</p>}
            {error && (
                <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
                    {error}
                </div>
            )}
            {imageSrc && (
                <div className="mt-4">
                    <img src={imageSrc} alt="Scanned parking sign" className="max-w-full h-auto rounded-lg shadow-lg" />
                </div>
            )}
            {extractedText && (
                <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                    <h3 className="font-semibold mb-2">Extracted Text:</h3>
                    <p className="whitespace-pre-wrap">{extractedText}</p>
                </div>
            )}
        </div>
    );
};

export default ParkingSignScanner;