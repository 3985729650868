import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Auth({ setUser }) {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const endpoint = isRegister
        ? `${process.env.REACT_APP_SERVER_URL}/api/auth/register`
        : `${process.env.REACT_APP_SERVER_URL}/api/auth/login`;

      const res = await fetch(endpoint, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.message || `HTTP error! status: ${res.status}`);
      }

      if (isRegister) {
        setSuccessMessage("Registration successful! Redirecting to login...");
        setTimeout(() => {
          setIsRegister(false);
          setSuccessMessage("");
        }, 3000);
      } else if (data.user) {
        setUser(data.user);
        navigate("/dashboard");
      } else {
        setError("Login failed: User data not returned");
      }
    } catch (err) {
      console.error("Auth error:", err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="relative isolate overflow-hidden bg-gray-100 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {isRegister ? "Create your account" : "Sign in to your account"}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={formData.username}
                onChange={handleInputChange}
                aria-describedby="username-error"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={formData.password}
                onChange={handleInputChange}
                aria-describedby="password-error"
              />
            </div>
          </div>

          {error && (
            <p id="username-error" className="mt-2 text-center text-sm text-red-600" aria-live="assertive">
              {error}
            </p>
          )}

          {successMessage && (
            <p id="success-message" className="mt-2 text-center text-sm text-green-600" aria-live="polite">
              {successMessage}
            </p>
          )}

          <div>
            <button
              type="submit"
              className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={loading}
            >
              {loading ? "Processing..." : (isRegister ? "Register" : "Sign in")}
            </button>
          </div>
        </form>

        <div className="text-center text-sm text-gray-500 mt-4">
          <button
            onClick={() => {
              setIsRegister(!isRegister);
              setError("");
              setSuccessMessage("");
            }}
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            {isRegister
              ? "Already have an account? Sign in here"
              : "Don't have an account? Register here"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Auth;
